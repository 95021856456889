<template>
  <van-form @submit="onSubmit">
    <van-cell-group title=" " inset>
      <van-field
        v-model="chainId"
        name="chainId"
        label="品牌连锁id"
        placeholder="请输入"
      />
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button round block :type="bind ? 'danger' : 'primary'" native-type="submit">
        {{ bind ? '解绑' : '绑定' }}
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'

export default {
  props: {
  },
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const state = reactive({
      storeId: Cookies.get('storeId'),
      store: {},
      chainId: '',
      bind: true
    })
    const onSubmit = (values) => {
      Toast.loading({ forbidClick: true, duration: 0 })
      post('/store.bindChain', {
        storeId: state.storeId,
        bind: !state.bind,
        chainId: state.chainId
      }).then(res => {
        Toast.clear()
        if (res.code === 0) {
          Toast.success()
          init()
        } else {
          Toast(res.msg)
        }
      })
    }
    const init = () => {
      post('/store.get', {
        storeId: state.storeId
      }).then(res => {
        if (res.data.chain) {
          state.chainId = res.data.chain.chainId
          state.bind = true
        } else {
          state.bind = false
        }
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmit
    }
  }
}
</script>

<style scoped>
</style>
